import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container, Text, Flex } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import PageLayout from '../components/layout';

const NotFoundPage = ({ data: { notFoundImage } }) => (
  <PageLayout>
    <SEO
      title="Page Not Found"
      keywords=""
      description="Sorry, nothing to see here"
    />
    <Container maxW="container.lg" mt={12} mb={12}>
      <Heading as="h1">
        Sorry, we can&apos;t find the page you are looking for
      </Heading>
      <Text mb={6}>
        We can&apos;t find that page. You could either{' '}
        <CanonicalLink to="/">go home</CanonicalLink> or{' '}
        <CanonicalLink to="/knowledgebase/">
          search our knowledgebase
        </CanonicalLink>
        .
      </Text>
      <Flex justify="center" mt={6}>
        <ButtonLink colorScheme="orange" to="/">
          Take me home
        </ButtonLink>
      </Flex>
      <Flex
        justify="center"
        mt={6}
        sx={{
          img: {
            transform: 'rotate(3deg)!important',
            padding: '12px',
          },
        }}
      >
        <GatsbyImage
          image={getImage(notFoundImage)}
          alt={notFoundImage.childImageSharp.name}
        />
      </Flex>
    </Container>
  </PageLayout>
);

export const query = graphql`
  query NotFoundPageQuery {
    notFoundImage: file(name: { eq: "error-broken-screen" }) {
      name
      childImageSharp {
        gatsbyImageData(height: 300, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`;

export default NotFoundPage;
